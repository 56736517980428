import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/market/banner'
export default {

  saveOrUpdate(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  toggleEnabled(data) {
    return axios.post(`${prefixPath}/toggleEnabled`, data)
  },

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  loadEditData() {
    return axios.get(`${prefixPath}/loadEditData`)
  },

}
