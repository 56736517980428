<template>
  <div v-if="$can('banner_list', 'banner')">
    <v-card>
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex flex-row-reverse pb-0">
          <div
            v-if="$can('banner_save', 'banner')"
            class="d-flex align-center "
          >
            <v-btn
              color="primary"
              class="me-3"
              @click="editItem()"
            >
              <span>新建Banner</span>
            </v-btn>
          </div>

          <div class="d-flex align-center ">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>

          <div class="d-flex align-center">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <v-text-field
              v-model="search.name"
              outlined
              dense
              label="标题"
            ></v-text-field>  
          </div>
          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.endTime"
              :hide="false"
              label="结束时间"
            ></DatePick>
          </div>
          <div class="d-flex align-center mr-5">
            <span>至</span>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.startTime"
              :hide="false"
              label="开始时间"
            ></DatePick>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-title class="">
        <v-tabs
          v-model="currentTab"
          style="box-shadow: none !important;"
          @change="reloadTab"
        >
          <v-tab>展示中</v-tab>
          <v-tab>已下架</v-tab>
          <v-tab>全部</v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          disable-filtering
          :headers="getHeader()"
          :items="banners"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <template #[`item.title`]="{ item }">
            <v-row>
              <v-col
                class="pa-2 align-self-center"
                cols="auto"
              >
                <!-- <img
                
                  style="max-width: 45px; max-height: 40px;"
                  :src="item.bannerImg"
                > -->
                <img
                  v-if="item.mediaType === 'image'"
                  style="max-width: 45px; max-height: 40px;"
                  @click.stop="previewImg(item.bannerImg)"
                  :src="item.bannerImg || require('@/assets/images/white.jpeg')"
                  alt=""
                  >
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.title }}  </span><br>
                <span
                  class="text-caption"
                >{{ item.startTime }}-{{ item.endTime }}</span>
              </v-col>
            </v-row>
          </template>

          <template #[`item.link`]="{ item }">
            <div v-if="item.bannerType === true ">
              <a
                :href="`qianzhui${item.promoteId}`"
                target="_blank"
              >
                <span>促销：#{{ item.promoteId }}-{{ item.promoteTitle }}</span>
              </a>
            </div>
            <div v-else>
              <a
                v-if="item.link"
                :href="`${item.link}`"
                target="_blank"
              >
                <span>{{ item.link }}</span>
              </a>
            </div>
          </template>

          <template #[`item.mediaType`]="{ item }">
            <v-chip
              v-if="item.mediaType === 'image'"
              small
              outlined
              color="warning"
              class="font-weight-semibold text-capitalize"
            >
              图片
            </v-chip>
            <v-chip
              v-else
              color="info"
              small
              outlined
              class="font-weight-semibold text-capitalize"
            >
              视频
            </v-chip>
          </template>

          <template #[`item.isEnabled`]="{ item }">
            <v-chip
              v-if="item.isEnabled === false"
              small
              class="font-weight-semibold text-capitalize"
            >
              已下架
            </v-chip>
            <v-chip
              v-else-if="item.isEnabled === true"
              color="primary"
              small
              class="font-weight-semibold text-capitalize"
            >
              展示中
            </v-chip>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="$can('banner_toggleEnabled', 'banner')"
              text
              dense
              x-small
              color="primary"
              @click="toggleEnabled(item)"
            >
              {{ item.isEnabled ? '下架' : '上架' }}
            </v-btn>
            <v-btn
              v-if="$can('banner_edit', 'banner')"
              text
              dense
              x-small
              color="primary"
              @click="editItem(item)"
            >
              编辑
            </v-btn>
            <v-btn
              v-if="$can('banner_delete', 'banner')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '80%' : '100%'"
      height="100%"
      app
    >
      <v-card
        height="100%"
      >
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}Banner</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-5"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-card-title>
              <p class="text-h6">
                基本信息
              </p>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
              <v-row>
                  <v-col class="">
                    <h3>媒体类型</h3>
                    <v-btn-toggle
                        class="mt-2"
                        v-model="editedItem.mediaType"
                        mandatory
                        color="primary"
                      >
                        <v-btn value="image">
                          图片
                        </v-btn>

                        <v-btn value="video">
                          视频
                        </v-btn>

                      </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="ml-1">
                    <v-checkbox
                      v-model="editedItem.isEnabled"
                      label="展示"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-file-input
                      v-if="editedItem.mediaType === 'image'"
                      v-model="currentImg"
                      :loading="loadings.bannerUploaderLoading"
                      :disabled="loadings.bannerUploaderLoading"
                      label="上传图片"
                      dense
                      outlined
                      hide-details
                      @change="uploadImage(currentImg)"
                    ></v-file-input>
                    <v-file-input
                      v-else
                      v-model="currentVideo"
                      :loading="loadings.bannerUploaderLoading"
                      :disabled="loadings.bannerUploaderLoading"
                      label="上传视频"
                      dense
                      outlined
                      hide-details
                      @change="uploadVideo(currentVideo)"
                    ></v-file-input>
                    <span class="text-caption mt-2 ml-3" >建议尺寸：3：4</span> <br>
                    <span class="text-caption mt-2 ml-3" v-if=" editedItem.mediaType === 'video'">视频大小不超过20M</span>
                    <div v-if="editedItem.bannerImg && editedItem.mediaType === 'image'">
                      <v-img
                      :src="editedItem.bannerImg"
                      class="mt-1 ml-3"
                      max-height="150"
                      max-width="250"
                    ></v-img>
                    </div>
                    <div v-if=" editedItem.bannerVideo && editedItem.mediaType === 'video' && !loadings.bannerUploaderLoading">
                      <video width="320" height="240" controls>
                        <source :src="editedItem.bannerVideo" type="video/mp4">
                      </video>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.title"
                      label="标题*"
                      outlined
                      :rules="rules.required"
                      counter="100"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                  >
                    <DatePick
                      v-model="editedItem.startTime"
                      label="开始时间"
                    ></DatePick>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                  >
                    <DatePick
                      v-model="editedItem.endTime"
                      :hide="true"
                      label="结束时间"
                    ></DatePick>
                    <span class="text-caption  ml-6">结束Banner时间将自动下架</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.sort"
                      label="排序"
                      type="number"
                      :rules="rules.required"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-tabs
                      v-model="editedTab"
                      style="box-shadow: none !important;"
                    >
                      <v-tab>默认</v-tab>
                      <v-tab v-if="editedItem.mediaType === 'image'">促销</v-tab>
                      <v-tab v-if="editedItem.mediaType === 'image'">公告</v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-tabs-items v-model="editedTab">
                      <v-tab-item class="mt-6">
                      </v-tab-item>
                      <v-tab-item class="mt-6">
                        <!-- v-select 自定义插槽 插槽内容 图片加标题 -->
                        <v-select
                          v-model="editedItem.promoteId"
                          :items="promotes"
                          item-text="title"
                          item-value="id"
                          label="促销"
                          outlined
                          dense
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar
                              size="30"
                              class="mr-2"
                              :tile="true"
                            >
                              <v-img
                                :src="item.promoteImg"
                                :alt="item.title"
                                :lazy-src="item.img"
                                height="30"
                                width="30"
                                contain
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-tab-item>
                      <v-tab-item class="mt-6">
                        <v-select
                          v-model="editedItem.noticId"
                          :items="notics"
                          item-text="title"
                          item-value="id"
                          label="公告"
                          outlined
                          dense
                        ></v-select>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-dialog
      v-model="previewImgDialog"
      max-width="500"
    >
      <v-card>
        <v-card-text>
          <v-img
            :src="previewImgUrl || require('@/assets/images/white.jpeg')"
            max-width="500"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 确定删除Banner:<span style="color: red">{{ deleteData.title }}</span>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem()"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiLinkVariant,
} from '@mdi/js'
import DatePick from '@/components/DatePicker'
import BannerApi from '@/api/market/banner'
import uploadImg from '@/api/upload'

export default {
  name: 'Banner',
  components: {
    DatePick,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiLinkVariant,
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
        bannerUploaderLoading: false,
      },
      banners: [
      ],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      search: {},
      editedItem: {
        startTime: '',
        endTime: '',
      },
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        title: '',
        bannerImg: '',
        bannerVideo: '',
        bannerType: 0,
        promoteId: undefined,
        isEnabled: true,
        sort: 1,
        link: '',
        startTime: '',
        endTime: '',
        mediaType: 'image',
      },
      currentTab: 0,
      status: 1,
      editedTab: 0,
      deleteData: {},
      startTimePickShow: false,
      currentImg: {},
      currentVideo: {},
      promotes: [],
      notics: [],
      commitFlag: false,
      domain: '',
      previewImgDialog: false,
      previewImgUrl: '',
    }
  },
  mounted() {
    const domain = location.host.split('.')[0]
    this.domain = domain
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '公告标题', value: 'title' },
        { text: '创建时间', value: 'createdTime' },
        { text: '排序', value: 'sort' },

        // { text: '链接', value: 'link' },
        { text: '媒体类型', value: 'mediaType' },
        { text: '状态', value: 'isEnabled' },
      ]

      if (this.$can('banner_edit', 'banner')
          || this.$can('banner_delete', 'banner')
          || this.$can('banner_toggleEnabled', 'banner')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        name: this.search.name,
        isEnabled: this.status,
      }
      BannerApi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.banners = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.banners.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      this.currentImg = {}
      this.currentVideo = {}
      if (this.$can('banner_save_edit_init', 'banner')) {
        BannerApi.loadEditData().then(res => {
          const { data } = res.data
          this.promotes = data.promotes
          this.notics = data.notics
        })
      }

      if (item) {
        this.editedIndex = this.banners.indexOf(item)

        this.editedTab = item.bannerType

        this.editedItem = item
      } else {
        this.editedIndex = -1

        this.editedItem = { ...this.defaultItem }
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) {
        return
      }

      this.loadings.submitLoading = true

      this.editedItem.bannerType = this.editedTab

      const submitData = {
        ...this.editedItem,
      }

      if (!this.editedItem.startTime.endsWith('00:00:00')) {
        submitData.startTime = `${this.editedItem.startTime} 00:00:00`
      }
      if (!this.editedItem.endTime.endsWith('23:59:59')) {
        submitData.endTime = `${this.editedItem.endTime} 23:59:59`
      }

      if(this.editedItem.mediaType === 'image') {
        this.editedItem.bannerVideo = ''
      } else {
        this.editedItem.bannerImg = ''
      }

      this.commitFlag = true
      BannerApi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')

          this.loadPage(this.pages)
          this.commitFlag = false
          this.editDialog = false
        })
        .finally(() => {
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      BannerApi
        .delete(this.deleteData.id)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
    toggleEnabled(item) {
      const saveData = {
        id: item.id,
        isEnabled: !item.isEnabled,
      }
      BannerApi.toggleEnabled(saveData).then(res => {
        this.$toast.success('修改成功！')
        this.toSearch()
      })
    },
    reloadTab() {
      if (this.currentTab === 2) {
        this.status = undefined
      } else if (this.currentTab === 1) {
        this.status = 0
      } else if (this.currentTab === 0) {
        this.status = 1
      }
      this.toSearch()
    },
    uploadImage(img) {
      if (img) {
        this.loadings.bannerUploaderLoading = true

        uploadImg
          .uploadImg(img)
          .then(res => {
            this.editedItem.bannerImg = res.data.data
          })
          .finally(() => {
            this.loadings.bannerUploaderLoading = false
          })
      }
    },
    uploadVideo(video) {
      if (video) {
        this.loadings.bannerUploaderLoading = true

        // 如果视频文件大于20M 提示文件过大
        if (video.size > 20 * 1024 * 1024) {
          this.$toast.error('文件过大')
          this.loadings.bannerUploaderLoading = false
          return
        }
        

        uploadImg
          .uploadVideo(video)
          .then(res => {
            this.editedItem.bannerVideo = res.data.data
            console.log(this.editedItem.bannerVideo);
          })
          .finally(() => {
            this.loadings.bannerUploaderLoading = false
          })
      }
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
  },
}
</script>
